import './App.scss';
import github from './assets/icon/github.svg';
import twitter from './assets/icon/twitter.svg';

function App() {

  const slogs = [
    "Good Morning",
    "Glad to Meet you",
    "Glad to message you",
    "Good to match with you",
    "We All Gonna Make It",
  ];

  return (
    <div className="App">
      <header className="App-header">
        <p className='App-title'>
          GM
        </p>
        <div className="App-slog-container">
          {/* <span>GM</span> */}
          <div className="App-slog">
              <ul>
                {slogs.map((s) => <li key={s}>{s}</li>)}
              </ul>
          </div>
        </div>
      </header>

      <footer className='App-footer'>
          <a href='https://twitter.com/gmgnchat' target="_blank">
            <img className='Icon-svg' src={twitter} />
          </a>
          <a href='https://github.com/gmlabsai/gm' target="_blank">
            <img className='Icon-svg' src={github} />
          </a>
      </footer>
    </div>
  );
}

export default App;
